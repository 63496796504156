export default {
    namespaced: true,

    state: () => ({
        token: false,
        username: "",
        password: "",
        user: false,
        isUserLoaded: false,
    }),
    mutations: {
        setUsernameAndPassword(state, { username, password }) {
            state.username = username;
            state.password = password;
        },
        setToken(state, token) {
            state.token = token;
        },
        setRole(state, role) {
            state.role = role;
        },
        setUser(state, user) {
            state.user = user;
            state.isUserLoaded = true;
        },
    },
    actions: {
        getTokenBySession(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getTokenBySession()
                    .then((response) => {
                        store.commit("setToken", response.data.token);
                        localStorage.setItem("token", response.data.token);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        login(store) {
            return new Promise((resolve, reject) => {
                WV.ApiMyspace.postLogin(store.state.username, store.state.password)
                    .then((response) => {
                        store.commit("setToken", response.accessToken);
                        store.commit("setRole", response?.role);
                        localStorage.setItem("token", response.accessToken);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },

        logout(store) {
            store.commit("setToken", false);
            localStorage.removeItem("token");
            document.location.reload();
        },
        getUser(store) {
            return new Promise((resolve) => {
                WV.Api.getUser().then((user) => {
                    store.commit("setUser", user);
                    resolve();
                });
            });
        },
    },
    getters: {
        hasToken(state) {
            return state.token !== false;
        },
        getToken(state, getters) {
            if (getters.hasToken) {
                return state.token;
            }
            if (localStorage.getItem("token")) {
                return localStorage.getItem("token");
            }
        },
        isUserLoaded(state) {
            return state.isUserLoaded;
        },
    },
};
